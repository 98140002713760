import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

export const colors = {
  background: {
    BasicDecorationBlue: "#EBF7FD",
    BasicDecorationGray: "#F2F2F2",
    DangerFill: "#FCF1F0",
    FooterBackground: "#D9EEFB",
    SuccessFill: "#F0F9EB",
    WarningFill: "#FFFAF2",
    // BrandingOpacity: 'rgba(60,172,235,0.9)',
  },
  brand: {
    Action: "#2C7EAB",
    BasicDecorationBlue: "#EBF7FD",
    Branding: "#3CACEB",
    DangerBackground: "#FDDCDA",
    DangerStatus: "#F44336",
    DangerText: "#FF5050",
    Decoration: "#24D9FF",
    DecorationTwo: "#1820D9",
    DisabledGray: "#E1E1E3",
    SuccessDecoration: "#DCEDDD",
    SuccessStatus: "#43A047",
    SuccessText: "#38CC3F",
    SurfaceBackground: "#EDF7FD",
    WarningBackground: "#FFF1D6",
    WarningDecoration: "#FFB41F",
    WarningText: "#D1941A",
  },
  primary: {
    1: "#000000",
    2: "#333333",
    3: "#666666",
    4: "#CCCCCC",
    5: "#E6E6E6",
    6: "#F2F2F2",
    7: "#FFFFFF",
  },
  textAndShapes: {
    BrandBlack: "#292A31",
    Decoration: "#F2F2F2",
    Disabled: "#D9D9D9",
    Input: "#4D4D4D",
    InputDisabledLabelText: "#9696A0",
    InputField: "#CCCCCC",
    Placeholder: "#B4B4BB",
  },
};

const breakpoints = createBreakpoints({
  lg: "1500px",
  md: "800px",
  sm: "320px",
  xl: "1900px",
  xs: "0px",
});

const overrides = {
  breakpoints,
  colors,
};

const theme = extendTheme(overrides);

export default {
  ...theme,
  fontSizes: {
    ...theme.fontSizes,
    "2xl": "32px",
    "4xl": "48px",
    lg: "16px",
    md: "14px",
    sm: "12px",
    xl: "24px",
  },
  fontWeights: {
    ...theme.fontWeights,
    bold: 700,
    hairline: 100,
    light: 300,
    medium: 500,
    normal: 400,
    semibold: 600,
    thin: 200,
  },
  fonts: {
    ...theme.fonts,
    body: "SFProDisplay",
    heading: "Gilroy",
  },
  letterSpacings: {
    ...theme.letterSpacings,
    wide: "0.2px",
    wider: "0.3px",
    widest: "0.75px",
  },
  lineHeights: {
    ...theme.lineHeights,
    base: "21px",
    normal: "28px",
    short: "19px",
    shorter: "16px",
    shortest: "15px",
    tall: "32px",
    taller: "48px",
    tallest: "72px",
  },
};
