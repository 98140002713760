// @ts-nocheck - getTextStyle doesnt work
import { DefaultTheme, Text as ChakraText, TextProps } from "@chakra-ui/react";
import React from "react";
import * as theme from "lib/styles/theme";

export enum TextType {
  TITLE_ACTIVE = "titleActive",
  TITLE_INPUT = "titleLabel",
  TITLE_INPUT_DISABLED = "titleInputDisabled",
  PARAGRAPH = "paragraph",
  PARAGRAPH_PLACEHOLDER = "paragraphPlaceholder",
  PARAGRAPH_BRANDING = "paragraphBranding",
  ERROR = "error",
  TERMS = "terms",
  BUTTON = "button",
  NAV_ACTIVE = "navActive",
  NAV_INACTIVE = "navInactive",
  NAV_SECONDARY = "navSecondary",
  LABEL = "label",
  LABEL_BLACK = "labelBlack",
  LABEL_SMALL = "labelSmall",
  HYPERLINKS = "hyperlinks",
}

interface OwnProps {
  // defaults to Body 3
  type?: TextType;
  children: React.ReactNode;
}

type Props = OwnProps & TextProps;

/**
 * Generic Chakra Text wrapper to allow easy matching / updating to styleguide
 * @deprecated - use Text instead
 * @param props
 */
export default function Text(props: Props): JSX.Element {
  const { type, defaultValue, children, ...otherProps } = props;
  const genericTextStyle = getTextStyle(type);
  const textTransform = getTextTransform(type);
  const textColor = getTextColor(type);
  return (
    <ChakraText
      {...genericTextStyle}
      {...otherProps}
      textColor={props.color || props.textColor || textColor}
      textTransform={props.textTransform || textTransform}
      defaultValue={defaultValue}
    >
      {children}
    </ChakraText>
  );
}

function getTextColor(
  type: TextType
): ColorProps.textColor | string | undefined {
  switch (type) {
    case TextType.TITLE_ACTIVE:
      return theme.colors.brand.Branding;
    case TextType.TITLE_INPUT:
      return theme.colors.textAndShapes.Input;
    case TextType.TITLE_INPUT_DISABLED:
      return theme.colors.textAndShapes.InputDisabledLabelText;
    // NOTE: Button type handled by Button.tsx
    case TextType.BUTTON:
      return undefined;
    case TextType.LABEL:
      return theme.colors.textAndShapes.Placeholder;
    case TextType.LABEL_BLACK:
      return theme.colors.textAndShapes.BrandBlack;
    case TextType.LABEL_SMALL:
      return theme.colors.textAndShapes.Placeholder;
    case TextType.PARAGRAPH:
      return theme.colors.textAndShapes.Input;
    case TextType.PARAGRAPH_PLACEHOLDER:
      return theme.colors.textAndShapes.Placeholder;
    case TextType.PARAGRAPH_BRANDING:
    case TextType.NAV_ACTIVE:
      return theme.colors.brand.Branding;
    case TextType.NAV_INACTIVE:
      return theme.colors.textAndShapes.Input;
    case TextType.NAV_SECONDARY:
      return theme.colors.textAndShapes.Input;
    case TextType.ERROR:
      return theme.colors.brand.DangerText;
    case TextType.TERMS:
      return theme.colors.textAndShapes.Input;
    case TextType.HYPERLINKS:
      return theme.colors.textAndShapes.Input;
    default:
      return undefined;
  }
}

function getTextTransform(
  type: TextType
): TypographyProps.textTransform | undefined {
  switch (type) {
    case TextType.BUTTON:
    case TextType.LABEL:
    case TextType.LABEL_BLACK:
    case TextType.NAV_INACTIVE:
      return "uppercase";
    case TextType.NAV_SECONDARY:
      return "none";
    default:
      return undefined;
  }
}

/**
 * Format the Text per the 10ure style guide,
 * Can add additional style props on top
 * @param type - TextType matches stylguide naming
 */
export function getTextStyle(type: TextType = TextType.PARAGRAPH): {
  fontWeight: keyof DefaultTheme["fontWeights"];
  fontSize: keyof DefaultTheme["fontSizes"];
  lineHeight: keyof DefaultTheme["lineHeights"] | "shortest" | "tallest";
  letterSpacing: keyof DefaultTheme["letterSpacings"];
} {
  switch (type) {
    case TextType.TITLE_ACTIVE:
    case TextType.TITLE_INPUT:
    case TextType.TITLE_INPUT_DISABLED:
      return {
        fontSize: "lg",
        fontWeight: "bold",
        letterSpacing: "widest",
        lineHeight: "normal",
      };
    case TextType.PARAGRAPH:
    case TextType.PARAGRAPH_PLACEHOLDER:
    case TextType.PARAGRAPH_BRANDING:
    default:
      return {
        fontSize: "lg",
        fontWeight: "medium",
        letterSpacing: "widest",
        lineHeight: "normal",
      };
    case TextType.ERROR:
    case TextType.TERMS:
      return {
        fontSize: "md", // maybe small
        fontWeight: "normal",
        letterSpacing: "widest",
        lineHeight: "base",
      };
    case TextType.BUTTON:
      return {
        fontSize: "lg",
        fontWeight: "bold",
        letterSpacing: "widest",
        lineHeight: "shorter",
      };
    case TextType.NAV_ACTIVE:
      return {
        fontSize: "md",
        fontWeight: "bold",
        letterSpacing: "widest",
        lineHeight: "shorter",
      };
    case TextType.NAV_INACTIVE:
      return {
        fontSize: "md",
        fontWeight: "medium",
        letterSpacing: "widest",
        lineHeight: "shorter",
      };
    case TextType.LABEL:
    case TextType.LABEL_BLACK:
      return {
        fontSize: "lg",
        fontWeight: "bold",
        letterSpacing: "wide",
        lineHeight: "short",
      };
    case TextType.LABEL_SMALL:
    case TextType.NAV_SECONDARY:
      return {
        fontSize: "sm",
        fontWeight: "bold",
        letterSpacing: "wide",
        lineHeight: "short",
      };
    case TextType.HYPERLINKS:
      return {
        fontSize: "lg",
        fontWeight: "normal",
        letterSpacing: "widest",
        lineHeight: "normal",
      };
  }
}
